import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import get from 'lodash/get';
import { useSafeSetState } from '@uptime/shared/hooks';
import { clearExternalState, clearJwtState, parseJwt } from '@uptime/shared/utils/general';
import { JWT_TOKEN_STORAGE_KEY } from '@uptime/shared/constants';
import { getAccessToken } from '../requests';

const INIT_STATE = {
  isLoading: true,
  error: undefined,
  loadingTitle: 'Impersonating',
};

const useImpersonate = () => {
  const location = useLocation();
  const { signature, identificator } = querystring.parse(location.search) || {};
  const [state, setState] = useSafeSetState(INIT_STATE);

  useEffect(
    () => {
      const login = async () => {
        if (!signature || !identificator) {
          setState({
            ...state,
            error: 'Invalid signature or identificator',
            isLoading: false,
          });
          return;
        }

        try {
          clearJwtState();

          const {
            data: { token },
          } = await getAccessToken({ signature, identificator });
          const tokenPayload = parseJwt(token);

          localStorage.setItem(
            JWT_TOKEN_STORAGE_KEY,
            JSON.stringify({
              ...tokenPayload,
              token,
              impersonate: true,
            })
          );

          clearExternalState();

          window.location.href = '/app';
        } catch (e) {
          setState({
            ...state,
            isLoading: false,
            error: get(e, 'graphQLErrors[0].message', 'Oops, something went wrong'),
          });
        }
      };

      login();
    },
    // eslint-disable-next-line
    []
  );

  return state;
};

export default useImpersonate;
