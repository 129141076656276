import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
  Typography,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = (props) => {
  const { open, handleClose, children, title, size, actions, isShowingTitleDivider, ...otherProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      maxWidth={size || 'sm'}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      fullWidth
      {...otherProps}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" data-testid="dialogTitle">
            {title}
          </Typography>
          <IconButton onClick={handleClose} size="large" data-testid="close">
            <CloseIcon />
          </IconButton>
        </Box>
        {isShowingTitleDivider && (
          <Box p={2} pl={0}>
            <Divider />
          </Box>
        )}
      </DialogTitle>
      <DialogContent data-testid="dialogContent">{children}</DialogContent>
      {actions && <DialogActions data-testid="dialogActions">{actions}</DialogActions>}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  actions: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  isShowingTitleDivider: PropTypes.bool,
};

export default Modal;
