import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const HelpMenuItem = () => {
  return (
    <List sx={{ py: 0 }}>
      <Box sx={{ padding: '0 20px' }}>
        <Divider />
      </Box>
      <ListItem button component="a" href="https://wiki.uptimehealth.com" target="_blank">
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Help Center" />
      </ListItem>
    </List>
  );
};

export default HelpMenuItem;
