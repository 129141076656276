import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    flex: '1 1 100%',
    maxWidth: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.components.MuiDrawer.styleOverrides.root.width,
      flexShrink: 0,
    },
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('md')]: {
      minHeight: 80,
    },
  },
  drawerPaper: {
    width: theme.components.MuiDrawer.styleOverrides.root.width,
    color: theme.palette.grey[800],
    height: '100% !important',
    backgroundColor: theme.palette.secondary.light,
  },
  alert: {
    '& > .MuiAlert-message': {
      width: '100%',
    },
    '& > .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    maxWidth: 'calc(100% - 290px)',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      // maxWidth: 'calc(100% - 80px)',
    },
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
    backgroundColor: theme.palette.grey.white,
  },
}));
