import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 200,
    borderRadius: 12,
  },
  infoSection: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 2,
    paddingLeft: 6,
  },
  userEmail: {
    margin: 0,
    color: theme.palette.grey[600],
    fontSize: 12,
  },
  caption: {
    color: theme.palette.grey[700],
    lineHeight: '20px',
    fontSize: '12px',
    fontWeight: 500,
  },
  logoutSection: {
    color: theme.palette.error.main,
  },
  userSection: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    minHeight: 30,
    display: 'flex',
  },
  navigationSection: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
}));
