import React, { useContext } from 'react';

import { UIContext } from 'providers/UIProvider';
import Confirmation from 'shared/components/Confirmation';

const ModalConfirmation = () => {
  const { modal, clearModalConfirmation } = useContext(UIContext);
  const { title, listName, onConfirm, onClose, confirmation, modalTitle = 'Delete' } = modal || {};

  const isOpenConfirmation = Boolean(modal);
  const confirmationQuestion = confirmation
    ? confirmation
    : `Are you sure want to remove "${title}" from the ${listName} list?`;

  const handleConfirm = () => {
    onConfirm();
    clearModalConfirmation();
  };

  const handleClose = () => {
    onClose && onClose();
    clearModalConfirmation();
  };

  return (
    <Confirmation
      open={isOpenConfirmation}
      onConfirm={handleConfirm}
      onClose={handleClose}
      title={modalTitle}
      buttonLabel={modalTitle}
    >
      {confirmationQuestion}
    </Confirmation>
  );
};

export default ModalConfirmation;
