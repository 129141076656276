import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useEffect, useState } from 'react';
import { clearExternalState, clearJwtState, getExpirationUIToken } from '@uptime/shared/utils/general';
import { JWT_TOKEN_STORAGE_KEY } from '@uptime/shared/constants';
import get from 'lodash/get';
import { loginAsUnifiedImagingToken } from 'shared/requests/unified-imaging';

const INIT_STATE = {
  isLoading: true,
  error: undefined,
  loadingTitle: 'Signing in to the system as Unified Imaging user',
};

const useUnifiedImagingLogin = () => {
  const location = useLocation();
  const { token: identifier, redirect_url: redirectUrl, deviceId } = querystring.parse(location.search) || {};

  const [state, setState] = useState(INIT_STATE);

  useEffect(
    () => {
      const login = async () => {
        if (!identifier || !redirectUrl) {
          return setState({
            ...state,
            isLoading: false,
            error: 'Invalid token or redirect url :(',
          });
        }

        const storage = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);

        if (storage) {
          const { hasExpired } = getExpirationUIToken(storage);

          const storedIdentifier = get(JSON.parse(storage), 'UI.identifier');
          const isSame = identifier === storedIdentifier;

          if (!hasExpired && isSame) {
            window.location.href = redirectUrl;
            return;
          }
        }

        try {
          clearJwtState();

          await loginAsUnifiedImagingToken({
            identifier,
          });

          clearExternalState();

          if (redirectUrl === 'request' && Boolean(deviceId)) {
            window.location.href = `/app/work-orders/hub?redirectUrl=${redirectUrl}&deviceId=${deviceId}`;
          } else {
            window.location.href = redirectUrl;
          }
        } catch (e) {
          return setState({
            ...state,
            isLoading: false,
            error: get(e, 'graphQLErrors[0].message', 'Oops, something went wrong'),
          });
        }
      };

      login();
    },
    // eslint-disable-next-line
    []
  );

  return state;
};

export default useUnifiedImagingLogin;
