import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button/Button';
import { ErrorIcon } from '@uptime/shared/components/Icons';

const ErrorMessage = (props) => {
  const { title, message, statusCode, children, ...otherProps } = props;
  const history = useHistory();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      flex={1}
      {...otherProps}
    >
      <Box mb={1}>
        <ErrorIcon />
      </Box>
      {statusCode && (
        <Box component="h4" m={0} fontSize={45}>
          {statusCode}
        </Box>
      )}
      <Box component="h4" mb={1} mt={0} fontSize={20}>
        {title}
      </Box>
      {message && (
        <Box color={theme.palette.grey[500]} mt={0} pt={0}>
          {message}
        </Box>
      )}
      {children ? (
        children
      ) : (
        <Box mt={2}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<HomeIcon />}
            onClick={() => history.push('/app/home')}
          >
            Go to Home
          </Button>
        </Box>
      )}
    </Box>
  );
};

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  statusCode: PropTypes.string,
  children: PropTypes.node,
};

export default ErrorMessage;
