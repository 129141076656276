import { useQuery } from '@apollo/client';
import { getBusinessCards } from '@uptime/shared/graphql/users';
import { userClient } from 'apolloClientConfiguration';

interface Props {
  accountId: string | null;
}

const useCompanySite = ({ accountId }: Props) => {
  const { data: { cards } = {} } = useQuery(getBusinessCards, {
    variables: {
      userIds: [accountId],
    },
    skip: !Boolean(accountId),
    client: userClient,
    fetchPolicy: 'cache-first',
  });

  const businessCard = cards?.length > 0 ? cards[0] : {};
  const companySiteUrl = Boolean(businessCard?.companySite) ? JSON.parse(businessCard?.companySite) : null;

  return companySiteUrl;
};

export default useCompanySite;
