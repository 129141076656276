import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingPlaceholder from '@uptime/shared/components/LoadingPlaceholder';
import useJwtLogin from './useJwtLogin';

const UnifiedImaging = () => {
  const { error, loadingTitle } = useJwtLogin();

  if (error) {
    return (
      <Box textAlign="center">
        <Typography variant="subtitle1" color="textPrimary">
          We got an error while logging you to the system :(
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Error: {error}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Please try again later or contact our support
        </Typography>
      </Box>
    );
  }

  return <LoadingPlaceholder title={loadingTitle} />;
};

export default UnifiedImaging;
