import { useQuery } from '@apollo/client';

import { getLogsCount } from '@uptime/shared/graphql/log';
import { LOG_STATUS } from '@uptime/shared/constants/logs';

import { flowClient } from 'apolloClientConfiguration';

interface Props {
  accountId: string | null;
  isRepairer: boolean;
  logsManagementPermission: boolean;
}

export default function useLogsCount({ accountId, isRepairer, logsManagementPermission }: Props) {
  const { data } = useQuery(getLogsCount, {
    variables: {
      filter: {
        accountId,
        status: LOG_STATUS.IN_PROGRESS,
        ignorePhantomStrategy: true,
      },
    },
    skip: isRepairer || !accountId || !logsManagementPermission,
    client: flowClient,
  });

  return {
    logsCount: data?.logList?.itemsCount || 0,
  };
}
