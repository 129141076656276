import React from 'react';

export default () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M74.9126 106.227H49.8848V118.74H74.9126V106.227Z" fill="#C1CED4" />
        <path d="M80.9214 117.483H43.8789V120H80.9214V117.483Z" fill="black" />
        <path
          d="M104.064 39.4472C103.477 38.8603 102.708 38.5669 101.939 38.5669H22.8479C22.0818 38.5669 21.3119 38.8603 20.7283 39.4472C20.1421 40.0333 19.8506 40.8025 19.8506 41.5699V96.2104H104.944V41.5701C104.943 40.8025 104.652 40.0329 104.064 39.4472ZM99.4379 90.7075H25.3552V44.072H99.4376V90.7075H99.4379Z"
          fill="#C1CED4"
        />
        <path
          d="M19.8496 96.2104V103.222C19.8496 103.989 20.1412 104.759 20.7273 105.343C21.3116 105.927 22.0808 106.226 22.847 106.226H101.938C102.708 106.226 103.476 105.927 104.063 105.343C104.65 104.759 104.942 103.989 104.942 103.222V96.2104H19.8496ZM102.425 103.222C102.425 103.313 102.402 103.445 102.287 103.559C102.157 103.689 102.014 103.708 101.939 103.708H22.8472C22.7741 103.708 22.6337 103.689 22.5045 103.561C22.3906 103.447 22.3672 103.314 22.3672 103.222V98.7285H102.426V103.222H102.425Z"
          fill="black"
        />
        <path d="M116.257 55.8182H113.739V50.2095H108.131V47.6919H116.257V55.8182Z" fill="black" />
        <path
          d="M3.74316 26.8472V58.7326H9.86043V67.1462L18.2752 58.7326H58.0922V26.8472H3.74316Z"
          fill="#2499EC"
        />
        <path
          d="M25.3552 58.7325V44.0718H58.0921V38.5669H22.8479C22.0818 38.5669 21.3119 38.8603 20.7283 39.4472C20.1421 40.0333 19.8506 40.8025 19.8506 41.5699V58.7325H25.3552Z"
          fill="#0064AA"
        />
        <path d="M11.1228 47.897H7.89551V52.2516H11.1228V47.897Z" fill="white" />
        <path d="M15.3823 43.8589H12.1543V52.2491H15.3823V43.8589Z" fill="white" />
        <path d="M19.6414 45.7251H16.4111V52.253H19.6414V45.7251Z" fill="white" />
        <path d="M23.8987 36.4146H20.6719V52.2518H23.8987V36.4146Z" fill="white" />
        <path d="M28.156 40.6191H24.9287V52.2535H28.156V40.6191Z" fill="white" />
        <path d="M32.4151 33.2632H29.1895V52.2504H32.4151V33.2632Z" fill="white" />
        <path d="M36.6728 42.3833H33.4443V52.2549H36.6728V42.3833Z" fill="white" />
        <path d="M40.9299 44.3315H37.7031V52.252H40.9299V44.3315Z" fill="white" />
        <path d="M45.1884 36.4146H41.96V52.2518H45.1884V36.4146Z" fill="white" />
        <path d="M49.4474 29.2129H46.2178V52.2523H49.4474V29.2129Z" fill="white" />
        <path
          d="M74.3206 8.7876C68.0093 8.7876 62.8975 13.9001 62.8975 20.21C62.8975 26.52 68.01 31.6362 74.3206 31.6362C80.6294 31.6362 85.7473 26.5202 85.7473 20.21C85.7473 13.8999 80.6296 8.7876 74.3206 8.7876Z"
          fill="#C1CED4"
        />
        <path
          d="M75.26 22.6968L58.248 7.89449L59.073 6.94529C62.9087 2.53142 68.4686 0 74.3281 0C75.2107 0 76.1023 0.0588266 76.9769 0.175308L78.2238 0.341476L75.26 22.6968ZM61.8318 7.67676L73.3796 17.7246L75.3919 2.5504C70.3082 2.23143 65.3813 4.13568 61.8318 7.67676Z"
          fill="black"
        />
        <path
          d="M73.7031 33.8426C73.9096 33.8523 74.1116 33.8745 74.3181 33.8738C77.5419 33.8757 80.4973 32.7531 82.8332 30.8877L77.4465 24.0664C76.5988 24.7545 75.5176 25.171 74.3373 25.1745C74.245 25.1776 74.1604 25.1684 74.0662 25.1637L73.7031 33.8426Z"
          fill="#E53935"
        />
        <path
          d="M78.8533 18.1841C79.7906 20.2821 79.1526 22.6816 77.4434 24.0651L84.0666 32.2387C89.3934 27.9291 91.3733 20.4279 88.4477 13.8911L78.8533 18.1841Z"
          fill="#2499EC"
        />
        <path
          d="M79.0128 54.8284C80.9092 54.8284 82.4466 53.2911 82.4466 51.3947C82.4466 49.4983 80.9092 47.9609 79.0128 47.9609C77.1164 47.9609 75.5791 49.4983 75.5791 51.3947C75.5791 53.2911 77.1164 54.8284 79.0128 54.8284Z"
          fill="#2499EC"
        />
        <path
          d="M106.061 35.3778C106.061 38.8924 103.212 41.7419 99.6969 41.7419C96.1846 41.7419 93.334 38.8926 93.334 35.3778C93.334 31.8648 96.1851 29.0156 99.6969 29.0156C103.211 29.0156 106.061 31.8641 106.061 35.3778Z"
          fill="#E53935"
        />
        <path
          d="M24.8259 30.2563C20.8229 30.2563 17.5654 26.999 17.5654 22.9953C17.5654 18.9904 20.8227 15.7324 24.8259 15.7324C28.8292 15.7324 32.0869 18.9904 32.0869 22.9953C32.0869 26.9988 28.829 30.2563 24.8259 30.2563ZM24.8259 18.25C22.2106 18.25 20.083 20.3788 20.083 22.9953C20.083 25.6113 22.2106 27.7387 24.8259 27.7387C27.442 27.7387 29.5693 25.6111 29.5693 22.9953C29.5693 20.3786 27.442 18.25 24.8259 18.25Z"
          fill="black"
        />
        <path
          d="M45.7909 17.5842C45.7909 19.0061 44.6389 20.158 43.217 20.158C41.7981 20.158 40.6455 19.0061 40.6455 17.5842C40.6455 16.1639 41.7974 15.0127 43.217 15.0127C44.6385 15.0127 45.7909 16.1642 45.7909 17.5842Z"
          fill="#C1CED4"
        />
        <path
          d="M104.063 39.4472C103.476 38.8603 102.708 38.5669 101.938 38.5669H94.1963C95.299 40.4629 97.3474 41.7414 99.6965 41.7414C101.516 41.7414 103.152 40.9741 104.311 39.7507C104.233 39.6476 104.158 39.5414 104.063 39.4472Z"
          fill="#C62828"
        />
        <path
          d="M27.5844 26.8472C26.8063 27.4064 25.8552 27.7387 24.8264 27.7387C23.7975 27.7387 22.8464 27.4057 22.0683 26.8472H18.6826C19.9691 28.8913 22.2387 30.2563 24.8259 30.2563C27.4136 30.2563 29.6834 28.8913 30.9697 26.8472H27.5844Z"
          fill="black"
        />
        <path
          d="M73.7959 31.61C73.9712 31.6178 74.1432 31.6365 74.3202 31.6365C77.0171 31.6365 79.492 30.6962 81.4469 29.1328L77.4462 24.0664C76.5985 24.7545 75.5173 25.171 74.3371 25.1745C74.2447 25.1776 74.1601 25.1684 74.0659 25.1637L73.7959 31.61Z"
          fill="#C62828"
        />
        <path
          d="M84.7449 15.5488L78.8543 18.1845C79.7915 20.2826 79.1536 22.6821 77.4443 24.0656L81.5111 29.0843C84.0934 26.9891 85.7471 23.7942 85.7471 20.2107C85.7471 18.5483 85.3841 16.9738 84.7449 15.5488Z"
          fill="#0064AA"
        />
        <path
          d="M77.0596 9.13265C76.2569 8.93461 75.4227 8.81883 74.5642 8.80078L73.3804 17.7249L66.591 11.8173C65.973 12.3866 65.4133 13.018 64.9326 13.7108L75.2608 22.6975L77.0596 9.13265Z"
          fill="black"
        />
        <path d="M43 87L63 52L83 87H43Z" fill="#E53935" />
        <path d="M64.8425 63.9146H61.1572V75.9991H64.8425V63.9146Z" fill="white" />
        <path d="M64.8425 79.2637H61.1572V82.5309H64.8425V79.2637Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
