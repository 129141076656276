import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 25,
  },
  name: {
    color: theme.palette.common.black,
    fontSize: 22,
    fontWeight: 500,
  },
  logout: {
    color: theme.palette.error.main,
  },
  home: {
    color: theme.palette.primary.main,
  },
  nested: {
    backgroundColor: theme.palette.secondary.light,
    paddingLeft: theme.spacing(9),
    height: '40px',
  },
  nested2Level: {
    backgroundColor: theme.palette.secondary.light,
    paddingLeft: theme.spacing(9),
  },
  sidebar: {
    backgroundColor: theme.palette.secondary.light,
    minHeight: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& .MuiListItem-root': {
      '&.Mui-selected .MuiListItemText-root .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemText-root .MuiTypography-root:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  companyLogo: {
    width: '52px !important',
    height: '52px !important',
  },
}));
