import isArray from 'lodash/isArray';
import { STICKY_FACILITY_FILTER_SETTING } from '../constants';

const EXCLUDE_ITEMS = ['select', 'actions', 'location'];

export const filterVisibleColumns = (columns) => {
  return columns.filter((item) => item.isVisible);
};

export const transformSettingsToData = (columns) =>
  columns
    .filter(({ columnId }) => columnId)
    .reduce(
      (accumulator, { id: alias, isVisible: isActive, columnId, title }, position) => [
        ...accumulator,
        {
          columnId,
          isActive,
          position,
        },
      ],
      []
    );

export const transformDataToSettings = (settings = [], headItems) =>
  headItems
    .map((item, index) => {
      const {
        position: sort,
        isActive: isVisible,
        columnId,
        title,
      } = settings.find(({ alias }) => alias === item.id) || {};

      return columnId
        ? {
            ...item,
            title,
            label: title,
            sort,
            columnId,
            isVisible,
          }
        : {
            ...item,
            sort: index,
          };
    })
    .sort((a, b) => (a.sort > b.sort ? 1 : -1));

export const getStickyTaskFilterSetting = (settings) => {
  if (!isArray(settings)) return {};

  const stickyFacilityFilter = settings.find((item) => item.title === STICKY_FACILITY_FILTER_SETTING);

  return stickyFacilityFilter ?? {};
};
