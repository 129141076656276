import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PaymentIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 40" {...props}>
      <path d="M47.985 11.02l-2.4 26.205c-.16 1.55-1.599 2.775-3.198 2.775h-.32L5.118 35.755c-1.642-.152-2.867-1.586-2.826-3.237A3.27 3.27 0 0 1 0 29.388V3.265C0 1.47 1.44 0 3.199 0h36.789c1.759 0 3.199 1.47 3.199 3.265v3.926l1.919.238c.88.081 1.6.49 2.16 1.224.559.653.799 1.551.719 2.367zM1.6 10.612h39.987V5.714H1.6zm0-7.347v.817h39.987v-.817c0-.898-.72-1.632-1.6-1.632H3.2c-.88 0-1.6.734-1.6 1.632zM3.2 31.02h36.788c.88 0 1.6-.734 1.6-1.632V12.245H1.6v17.143c0 .898.72 1.632 1.6 1.632zM46.065 9.47c-.24-.327-.64-.572-1.04-.572l-1.84-.163v20.653c0 1.796-1.44 3.265-3.199 3.265H3.92c.08.735.64 1.388 1.44 1.47l36.868 4.244c.88.082 1.68-.571 1.76-1.47l2.398-26.203c.08-.408-.08-.898-.32-1.225zM20.16 20c0 .571-.307.952-.768.952h-2.304c-.46 0-.768-.38-.768-.952 0-.571.307-.952.768-.952h2.304c.46 0 .768.38.768.952zM3.84 20c0-.571.282-.952.704-.952h9.152c.422 0 .704.38.704.952 0 .571-.282.952-.704.952H4.544c-.422 0-.704-.38-.704-.952zm8.64-3.81c0-.571.307-.952.768-.952h6.144c.46 0 .768.381.768.952 0 .572-.307.953-.768.953h-6.144c-.46 0-.768-.381-.768-.953zm-8.64 0c0-.571.269-.952.672-.952h5.376c.403 0 .672.381.672.952 0 .572-.269.953-.672.953H4.512c-.403 0-.672-.381-.672-.953zm20.63 11.516v-7.793c0-.52.314-.865.785-.865h12.549c.47 0 .784.346.784.865v7.793c0 .52-.313.865-.784.865h-12.55c-.47 0-.784-.346-.783-.865zm1.57-.866h10.98v-6.06H26.04z" />
    </SvgIcon>
  );
};

export default PaymentIcon;
