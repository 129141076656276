import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.error.main,
    padding: '6px 16px 6px 16px',
    borderRadius: 16,
    color: theme.palette.secondary.light1,
    width: '32px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    lineHeight: '16px',
  },
}));
