import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Modal from '@uptime/shared/components/Modal';
import useStyles from './styles';

const Confirmation = (props) => {
  const classes = useStyles();
  const { onConfirm, onClose, open, title, children, buttonLabel, additional } = props;

  return (
    open && (
      <Modal open={open} handleClose={onClose} title={title} size="xs">
        <DialogContent>
          <Typography component="div" className={classes.typography}>
            {children}
          </Typography>
          {additional}
        </DialogContent>
        <Box p={2}>
          <Divider />
        </Box>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            {buttonLabel || 'Confirm'}
          </Button>
        </DialogActions>
      </Modal>
    )
  );
};

Confirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additional: PropTypes.any,
  buttonLabel: PropTypes.string,
};

export default Confirmation;
