import styled from 'styled-components';

import theme from '../../theme/muiTheme';

export const StyledLoading = styled.div`
  &:after {
    content: '.';
    font-size: 24px;
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: ${theme.palette.grey[400]};
      text-shadow:
        0.25em 0 0 ${theme.palette.grey.white},
        0.5em 0 0 ${theme.palette.grey.white};
    }
    40% {
      color: ${theme.palette.grey[600]};
      text-shadow:
        0.25em 0 0 ${theme.palette.grey.white},
        0.5em 0 0 ${theme.palette.grey.white};
    }
    60% {
      text-shadow:
        0.25em 0 0 ${theme.palette.grey[600]},
        0.5em 0 0 ${theme.palette.grey.white};
    }
    80%,
    100% {
      text-shadow:
        0.25em 0 0 ${theme.palette.grey[600]},
        0.5em 0 0 ${theme.palette.grey[400]};
    }
  }
`;
