import React from 'react';
import { Box, Grid } from '@mui/material';

type Props = {
  children: React.ReactNode;
  testid?: string;
};

export const BaseLoginInput = ({ children, testid }: Props) => (
  <Box component={Grid} item xs={12} pr={1.25} mb={2} data-testid={testid}>
    <>{children}</>
  </Box>
);
