import React, { useReducer } from 'react';
import * as PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { FACILITY_STAFF_ROLE, REPAIRER_ROLE } from '@uptime/shared/constants';

import AuthContext from '../context/AuthContext';

const initialState = {
  userId: null,
  role: null,
  accountId: null,
  isRepairer: false,
  logout: () => null,
  setIdState: () => null,
};

const SET_AUTH_STATE = 'SET_AUTH_STATE';
const SET_ID_STATE = 'SET_ID_STATE';
const SET_FACILITY_DATA = 'TECHNICIAN/SET_FACILITY_DATA';

function getRole(payload) {
  const isTopLevel = payload.userId === payload.accountId;
  const isRepairer = payload.type === REPAIRER_ROLE;

  if (isTopLevel) return payload.type;

  if (isRepairer) {
    return REPAIRER_ROLE;
  }

  return FACILITY_STAFF_ROLE;
}

function reducer(state, action) {
  const { type, payload } = action;

  if (type === SET_AUTH_STATE) {
    const role = getRole(payload);
    const userGroup = payload['user-groups'];
    const accountId = payload.accountId || payload.userId;
    const stGroups = payload['st_groups'];
    const roles = payload['roles'];
    const isSubAccount = role === FACILITY_STAFF_ROLE;
    const isManager = stGroups?.includes('ST:manager') || roles?.includes('ST:manager');
    const isStaff = isSubAccount && !isManager;
    const isRepairer = payload.type === REPAIRER_ROLE;
    const isTopLevel = payload.userId === payload.accountId;
    const isTopLevelFacility = isTopLevel && !isRepairer;
    const isTopLevelRepairer = isTopLevel && isRepairer;

    return {
      ...state,
      ...payload,
      accountId,
      userGroup,
      role,
      isManager,
      isStaff,
      isSubAccount,
      isTopLevel,
      isRepairer,
      isTopLevelRepairer,
      isTopLevelFacility,
    };
  }

  if (type === SET_ID_STATE) {
    const role = payload.type ? { role: payload.type } : {};

    return {
      ...state,
      ...payload,
      ...role,
    };
  }

  if (type === SET_FACILITY_DATA) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
}

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateAuth = (tokenPayload) => {
    if (!Boolean(tokenPayload.userId)) {
      return;
    }

    if (tokenPayload.userId && tokenPayload.userId !== state.userId && state.userId === null) {
      dispatch({
        type: SET_AUTH_STATE,
        payload: tokenPayload,
      });
      Sentry.setUser(tokenPayload.userId);
    }
  };

  const logout = async (auth) => {
    await auth.logout();
  };

  const setIdState = (payload) =>
    dispatch({
      type: SET_ID_STATE,
      payload,
    });

  const setFacilityData = (payload) =>
    dispatch({
      type: SET_FACILITY_DATA,
      payload,
    });

  return (
    <AuthContext.Provider value={{ ...state, updateAuth, logout, setIdState, setFacilityData }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
