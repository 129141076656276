import { gql } from '@apollo/client';

export const getHomeTablesSettings = gql`
  query getHomeTablesSettings($userId: Int!) {
    getHomeTablesSettings(userId: $userId) {
      tasks {
        settingId
        isActive
        position
      }
      serviceRequests {
        settingId
        isActive
        position
      }
      documents {
        settingId
        isActive
        position
      }
      logs {
        settingId
        isActive
        position
      }
    }
  }
`;

export const getUserFeatures = gql`
  query getUserFeatures {
    getUserFeatures
  }
`;

export const getWhiteLabel = gql`
  query getWhiteLabel($accountId: Int!) {
    whiteLabel: getWhiteLabel(accountId: $accountId) {
      accountId
      name
      email
      address
      city
      state
      postal
      site
      description
      financeUrl
      colors
      logoUrl
    }
  }
`;

// Mutations
export const updateHomeTablesSettings = gql`
  mutation updateHomeTablesSettings($userId: Int!, $settings: HomeTablesSettingsInput) {
    updateHomeTablesSettings(userId: $userId, settings: $settings) {
      tasks {
        settingId
        isActive
        position
      }
      serviceRequests {
        settingId
        isActive
        position
      }
      documents {
        settingId
        isActive
        position
      }
    }
  }
`;
