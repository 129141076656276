import { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, CssBaseline, Drawer, Hidden, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import HelpOutLineIcon from '@mui/icons-material/HelpOutline';
import { Query } from '@apollo/client/react/components';

import { getAccountProfile } from '@uptime/shared/graphql/users';
import WrapLink from '@uptime/shared/components/WrapLink';
import { FACILITY_ROLE } from '@uptime/shared/constants';
import { getStepsForRole } from '@uptime/shared/utils/account';
import useFetchImageUrl from '@uptime/shared/hooks/useFetchImageUrl';
import { getProfileBusinessFileUrl, getProfileFileUrl } from '@uptime/shared/graphql/documents';

import usePermissions from 'shared/hooks/usePermissions';

import { userClient } from '../../apolloClientConfiguration';
import AuthContext from '../../context/AuthContext';
import { SourceContext } from '../../providers/SourceProvider';
import useCompanySite from './LeftNav/hooks/useCompanySite';
import LeftNav from './LeftNav/LeftNav';
import Header from './Header/Header';
import useStyles from './styles';
import ModalConfirmation from './partials/ModalConfirmation';

const Layout = (props) => {
  const { children } = props;
  const { userId, accountId, role } = useContext(AuthContext);
  const { whiteLabel } = useContext(SourceContext);
  const permissions = usePermissions();

  const [steps] = getStepsForRole(role, permissions);

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const companySiteUrl = useCompanySite({ accountId });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const fetchBusinessImageState = useFetchImageUrl({ userId: accountId }, getProfileBusinessFileUrl);
  const fetchImageState = useFetchImageUrl({ userId }, getProfileFileUrl);

  const layout = ({ data }) => {
    const userProfile = data && data.profile;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <ModalConfirmation />
        <Header menuToggle={handleDrawerToggle} user={userProfile} image={fetchImageState} />
        <nav className={classes.drawer} aria-label="Mailbox folders">
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerClose}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <LeftNav
                user={{ ...userProfile, companySiteUrl }}
                clicked={handleDrawerClose}
                image={fetchBusinessImageState}
                whiteLabel={whiteLabel}
                stepsCount={steps.length}
              />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <LeftNav
                user={{ ...userProfile, companySiteUrl }}
                clicked={handleDrawerClose}
                image={fetchBusinessImageState}
                whiteLabel={whiteLabel}
                stepsCount={steps.length}
              />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Box className={classes.toolbar} mt={-4} />
          {role !== FACILITY_ROLE && userProfile && steps.length > userProfile.step && (
            <Box pt={2}>
              <Alert severity="error" className={classes.alert} icon={<HelpOutLineIcon fontSize="inherit" />}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box>Please complete your account info</Box>
                  <Button
                    component={WrapLink}
                    variant="contained"
                    color="primary"
                    to="/app/settings/account"
                    data-testid="goToAccount"
                  >
                    Go to account
                  </Button>
                </Box>
              </Alert>
            </Box>
          )}
          {children}
        </main>
      </div>
    );
  };

  layout.propTypes = {
    data: PropTypes.objectOf({
      fetchProfile: PropTypes.object,
    }).isRequired,
  };

  return userId ? (
    // TODO:A - check if we need this
    <Query
      query={getAccountProfile}
      variables={{
        userId,
      }}
      client={userClient}
      skip={!userId}
    >
      {layout}
    </Query>
  ) : (
    children
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
