import React from 'react';
import { Box, Grid, Divider, useTheme, Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const BaseLoginTitle = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Box component={Grid} container item xs={12} justifyContent="center" my={5}>
        <Typography color={theme.palette.grey[900]} variant="h2">
          <strong>{children}</strong>
        </Typography>
      </Box>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
