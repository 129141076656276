const CREATE_JOB_TITLE = 'Create Job';
const REPORT_REPAIR_TITLE = 'Report Repair';

export const renameFlow = ({ title, ...rest }: { title: string }) => {
  if (title.toLowerCase().includes(CREATE_JOB_TITLE.toLowerCase())) {
    return {
      title: title.replace(CREATE_JOB_TITLE, 'Request Service'),
      ...rest,
    };
  }

  if (title.toLowerCase().includes(REPORT_REPAIR_TITLE.toLowerCase())) {
    return {
      title: title.replace(REPORT_REPAIR_TITLE, 'Document Service'),
      ...rest,
    };
  }

  return { title, ...rest };
};
