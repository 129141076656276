import React, { createContext, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import isObject from 'lodash/isObject';

import muiTheme, { secondaryColorsWL } from '@uptime/shared/theme/muiTheme';
import getOverriddenStyles from '@uptime/shared/theme/overriddenStyles';

type PrimaryColors = {
  dark: string;
  main: string;
  light1: string;
  light2: string;
  light3: string;
};
type CustomThemeContextOptions = {
  setPrimaryColorsWL: React.Dispatch<React.SetStateAction<PrimaryColors | undefined>>;
};

const initialState: CustomThemeContextOptions = {
  setPrimaryColorsWL: () => null,
};

export const CustomThemeContext = createContext<CustomThemeContextOptions>(initialState);

type Props = {
  children: React.ReactNode;
};

const CustomThemeProvider = (props: Props) => {
  const { children } = props;
  const [primaryColorsWL, setPrimaryColorsWL] = useState<PrimaryColors>();

  const whiteLabelTheme = {
    ...muiTheme,
    palette: {
      ...muiTheme.palette,
      ...(isObject(primaryColorsWL)
        ? {
            primary: {
              ...muiTheme.palette.primary,
              ...primaryColorsWL,
            },
            secondary: {
              ...muiTheme.palette.secondary,
              ...secondaryColorsWL,
            },
          }
        : {}),
    },
  };
  const theme = createTheme({
    ...whiteLabelTheme,
    components: getOverriddenStyles(whiteLabelTheme),
  });

  return (
    <CustomThemeContext.Provider value={{ setPrimaryColorsWL }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
