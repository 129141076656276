import * as yup from 'yup';
import { COGNITO_PASSWORD_REGEXP } from 'libs/services/cognito';

export default yup.object({
  username: yup.string().nullable().required('Please enter an username').label('Username').max(255),
  password: yup
    .string()
    .nullable()
    .required('Please enter a new password')
    .label('New password')
    .max(255)
    .matches(COGNITO_PASSWORD_REGEXP, 'Password does not meet requirements')
    .test('is-the-same', 'New password must match', (value, { parent }) => value === parent.confirm),
  confirm: yup
    .string()
    .nullable()
    .required('Please enter a confirm password')
    .label('Confirm new password')
    .max(255)
    .matches(COGNITO_PASSWORD_REGEXP, 'Password does not meet requirements')
    .test('is-the-same', 'Confirm password must match', (value, { parent }) => value === parent.password),
});
