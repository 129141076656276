import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  splashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  loadingText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));
