import React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

import AnimatedLogo from '@uptime/shared/components/AnimatedLogo';
import TextLogo from '@uptime/shared/components/TextLogo';
import styles from './styles';

const Splash = (props) => {
  const { open } = props;
  const classes = styles();

  return (
    <Dialog fullScreen open={open}>
      <Box className={classes.splashContainer}>
        <AnimatedLogo />
        <Box pt={2}>
          <TextLogo />
        </Box>
        <Box pt={2}>
          UptimeController is{' '}
          <Box component="span" className={classes.loadingText}>
            Loading
          </Box>
        </Box>
        <Box>Please wait...</Box>
      </Box>
    </Dialog>
  );
};

Splash.propTypes = {
  open: PropTypes.bool,
};

export default Splash;
