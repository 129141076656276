import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { JwtContext } from '../context/JwtContext';
import useJwtInitialization from '../shared/hooks/useJwtInitialization';

const JwtProvider = ({ children }) => {
  const { error, shouldShowUIWarning, ...jwt } = useJwtInitialization();

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexDirection="column"
      >
        <Typography variant="subtitle1" color="textPrimary">
          We got an error while session refresh :(
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Error: {error}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Please launch a new session from Unified Imaging or contact our administrator
        </Typography>
      </Box>
    );
  }

  if (shouldShowUIWarning) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        Your session has timed out. Please launch a new session from Unified Imaging.
      </Box>
    );
  }

  return <JwtContext.Provider value={jwt}>{children}</JwtContext.Provider>;
};

JwtProvider.propTypes = {
  children: PropTypes.node,
};

export default JwtProvider;
