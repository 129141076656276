export const SYS_FIELDS_REGEX = /^s_/;
export const FORM_FIELDS_REGEX = /^t_/;
export const FIELD_TYPE = {
  CHECKBOX: 'checkbox',
  TEXT: 'text',
  DATEPICKER: 'datepicker',
  UPLOAD: 'upload',
  RADIO_GROUP: 'radiogroup',
  DROPDOWN: 'dropdown',
  LABEL: 'label',
  NUMBER: 'number',
};
export const FIELD_NAME = {
  INITIALS: 'initials',
};
export const FIELD_VALUE = {
  TODAY: 'today',
};
export const LOGS_WIDGETS = {
  GLUCOSE: 'glucose_box_control',
  MONITOR_QC: 'monitor_qc_serial_numbers',
  DIPSTICK_URINALYSIS: 'dipstick_urinalysis',
  HEMOCUE_GLUCOSE_DAILY_3LEVELCONTROLML: 'hemocue_glucose_daily_3levelcontrolml',
  HEMOCUE_GLUCOSE_2013LEVELCONTROLML: 'hemocue_glucose_2013levelcontrolml',
  HEMOCUE_HEMOGLOBIN_HIGHLOWCONTROLML: 'hemocue_hemoglobin_highlowcontrolml',
  ABBOTT_AFINION_HBA1C_EXTERNALQCLOGML: 'abbott_afinion_hba1c_externalqclogml',
  CLINTEK_PROTEIN_GLUCOSE_CONTROLML: 'clintek_protein_glucose_controlml',
};

export const PHANTOM_2D_LOG = 'PhantomControl2DControlLog';
export const PHANTOM_3D_LOG = 'PhantomControl3DControlLog';
export const RESOLUT_2D_LOG = 'WeeklyACRMapReport2D';
export const RESOLUT_3D_LOG = 'WeeklyACRMapReport3D';
export const HOMOGENEITY_LOG = 'WeeklyHomogeneity';

export const PHANTOM_TITLES = {
  fibers: 'Fibers Seen',
  specs: 'Specs Seen',
  masses: 'Masses Seen',
  sValue: 'S Value',
  mas: 'mAs (optional)',
};

export const LOG_STRATEGY = {
  COMMON: 'common',
  PHANTOM: 'phantom',
};

export const LOG_STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'InProgress',
};

export const DEFAULT_LOG_PAGINATION = {
  PAGE: 1,
  ITEMS_AMOUNT: 10,
};

export const DEFAULT_LOG_SORTING = {
  FIELD: 'createdAt',
  ORDER: 'ASC',
};

export const ACTIONS_TAKEN_FIELD = 'actionsTaken';
export const CORRECTIVE_ACTION_FIELD = 'correctiveActionRequired';
