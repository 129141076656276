import { makeStyles } from '@mui/styles';

export const flexSettings = {
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// TODO: should be replaced in mui v5 to overrides
export const useInputStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.light1,
    padding: theme.spacing(0, 0.5),
    minHeight: 40,
    borderRadius: 6,
    flexDirection: 'row',
    border: '1px solid transparent',
    fontSize: '.75rem !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.secondary.light1,
    },
    '&:focus-within': {
      borderColor: `${theme.palette.primary.main} !important`,
      backgroundColor: theme.palette.grey.white,
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.grey.white,
      },
    },
    '&:not(:placeholder-shown)': {
      color: theme.palette.grey.black,
    },
    '& textarea::placeholder': {
      fontSize: '.75rem',
      color: theme.palette.grey[600],
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light3}`,
    },
    '& input': {
      paddingLeft: `${theme.spacing(1.5)} !important`,
      fontSize: '.75rem',
      background: theme.palette.secondary.light1,
      '&::placeholder': {
        color: theme.palette.grey[600],
      },
      '&:focus': {
        backgroundColor: theme.palette.grey.white,
      },
    },
    '& textarea': {
      fontSize: '.75rem',
    },
  },
  clear: {
    cursor: 'pointer',
  },
  label: {
    color: theme.palette.grey[800],
    fontSize: '0.9rem',
  },
}));

export const useDatePickerStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.light1,
    minHeight: 40,
    width: '100%',
    borderRadius: 6,
    flexDirection: 'row',
    border: '1px solid transparent',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '& .MuiInputBase-root': {
      width: '100%',
      background: theme.palette.secondary.light1,
    },
    '&:focus-within': {
      borderColor: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.grey.white}`,
      '& .MuiOutlinedInput-root': {
        backgroundColor: `${theme.palette.grey.white}`,
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light3}`,
    },
    '& input': {
      width: '100%',
      fontSize: '.75rem',
      padding: 0,
      paddingLeft: theme.spacing(1.5),
      background: theme.palette.secondary.light1,
      '&::placeholder': {
        color: theme.palette.grey[600],
      },
      '&:focus': {
        backgroundColor: `${theme.palette.grey.white}`,
      },
    },
  },
}));

export const useRadioStyles = makeStyles((theme) => ({
  label: {
    fontSize: '0.9rem',
    color: theme.palette.grey[800],
  },
  radioContained: {
    padding: theme.spacing(1),
    '& .MuiButtonBase-root': {
      ...flexSettings,
      height: 'auto',
      borderRadius: 0,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      marginRight: theme.spacing(0.25),
    },
    '& .MuiTypography-root': {
      ...flexSettings,
      height: 42,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiButtonBase-root, & .MuiTypography-root': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.checked .MuiButtonBase-root, &.checked .MuiTypography-root': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  primary: {
    '&.checked .MuiButtonBase-root, &.checked .MuiTypography-root': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioRow: {
    flexDirection: 'row',
  },
  radioColumn: {
    flexDirection: 'column',
  },
  small: {
    '& .MuiTypography-root': {
      fontSize: '.875rem',
      color: theme.palette.grey[800],
    },
  },
  medium: { '& .MuiTypography-root': { fontSize: '.925rem', color: theme.palette.grey[800] } },
  large: { '& .MuiTypography-root': { fontSize: '1rem', color: theme.palette.grey[800] } },
}));

export const useWeekdayStyles = makeStyles((theme) => ({
  day: {
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    backgroundColor: (props) =>
      // @ts-ignore
      props.selected ? theme.palette.primary.main : theme.palette.primary.light3,
    // @ts-ignore
    color: theme.palette.grey.white,
    fontSize: '0.9rem',
  },
}));

export const useOptionStyles = makeStyles((theme) => ({
  option: {
    '&.MuiAutocomplete-option.Mui-focused': {
      backgroundColor: `${theme.palette.primary.light1} !important`,
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: `calc(100% - 10px)`,
  },
}));
