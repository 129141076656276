import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import ErrorMessage from '@uptime/shared/components/ErrorMessage';

const DEFAULT_MESSAGE = 'You don’t have permission to this feature, please contact your administrator';

const Forbidden = (props) => {
  const { message = DEFAULT_MESSAGE } = props;

  return (
    <Box pt="6%">
      <ErrorMessage title="Permission denied" message={message} statusCode="403" />
    </Box>
  );
};

Forbidden.propTypes = {
  message: PropTypes.string,
};

export default Forbidden;
