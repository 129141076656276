import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NotificationIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path d="M17.64 7.813a.832.832 0 0 1-.445 1.09 7.591 7.591 0 0 0-2.352 1.557 7.254 7.254 0 0 0-1.595 2.273 6.67 6.67 0 0 0-.585 2.727.834.834 0 0 1-1.668 0c0-1.172.245-2.317.728-3.403a9.003 9.003 0 0 1 1.959-2.794 9.223 9.223 0 0 1 2.866-1.896.838.838 0 0 1 1.092.446zm20.241 24.395a1.61 1.61 0 0 1 .319 1.767A1.716 1.716 0 0 1 36.613 35H26.421c-.75 2.89-3.375 5-6.421 5-3.047 0-5.672-2.11-6.422-5H3.387a1.711 1.711 0 0 1-1.585-1.027 1.61 1.61 0 0 1 .318-1.768l4.428-4.69a4.004 4.004 0 0 0 1.114-2.75V15.46c0-5.455 3.903-10.197 9.06-11.655-.024-.157-.055-.31-.055-.472A3.337 3.337 0 0 1 20 0a3.337 3.337 0 0 1 3.333 3.333c0 .149-.03.289-.048.43a12.576 12.576 0 0 1 5.427 2.96C31.053 8.948 32.34 11.885 32.34 15v9.767c0 1.011.395 1.988 1.113 2.75zM18.333 3.333c0 .049.025.082.029.129a12.186 12.186 0 0 1 1.661-.129l.354.005c.426.012.846.049 1.265.102.003-.038.025-.067.025-.107 0-.92-.749-1.666-1.667-1.666s-1.667.746-1.667 1.666zM24.703 35h-9.405c.692 1.953 2.565 3.333 4.702 3.333s4.01-1.38 4.703-3.333zm11.965-1.685l-4.428-4.657a5.662 5.662 0 0 1-1.568-3.893V15c0-2.653-1.104-5.162-3.105-7.063-1.94-1.812-4.512-2.855-7.237-2.932L20.023 5C14.227 5 9.328 9.79 9.328 15.46v9.305a5.662 5.662 0 0 1-1.568 3.893L3.332 33.35l10.773-.005c.005 0 .008-.003.012-.003.021-.004.041.005.063.003l22.433-.012c.035 0 .052-.01.055-.018zm-5.581-27.76a.836.836 0 0 1 .005-1.18.837.837 0 0 1 1.178.005 15.143 15.143 0 0 1 3.263 4.887A14.935 14.935 0 0 1 36.667 15 .834.834 0 0 1 35 15c0-1.767-.338-3.482-1.007-5.097a13.503 13.503 0 0 0-2.906-4.348zM3.333 15c0-4 1.562-7.765 4.399-10.602A.832.832 0 1 1 8.91 5.577 13.24 13.24 0 0 0 5 15a.834.834 0 0 1-1.667 0zM40 15a.834.834 0 0 1-1.667 0 16.55 16.55 0 0 0-1.26-6.37 16.854 16.854 0 0 0-3.638-5.442.834.834 0 0 1 1.183-1.175 18.519 18.519 0 0 1 3.994 5.979A18.195 18.195 0 0 1 40 15zM1.667 15A.834.834 0 0 1 0 15c0-4.89 1.91-9.49 5.375-12.957a.832.832 0 1 1 1.178 1.179C3.403 6.373 1.667 10.557 1.667 15z" />
    </SvgIcon>
  );
};

export default NotificationIcon;
