import React, { HTMLInputTypeAttribute } from 'react';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import { Control, Controller, FieldValue } from 'react-hook-form';

// TODO - replace on mui 5 to overrides
import { useInputStyles } from './styles';

import { getErrorMessage, getHasError } from './helpers';

const Type = {
  Number: 'number',
  Text: 'text',
};

type InputProps = {
  control: Control<FieldValue<any>> | undefined;
  name: string;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
  type?: HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  step?: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  autoComplete?: string;
};

const Input = ({
  control,
  name,
  label,
  type,
  placeholder,
  rows,
  multiline = false,
  fullWidth = false,
  required = false,
  disabled = false,
  min,
  max,
  step,
  startAdornment,
  endAdornment,
  autoComplete,
}: InputProps) => {
  // @ts-ignore
  const classes = useInputStyles();

  const hasFieldError = getHasError(name);
  const getFieldErrorMessage = getErrorMessage(name);

  return (
    // @ts-ignore
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => (
        <>
          {label && (
            <Box mb={1} display="flex" justifyContent="space-between" width="100%">
              <InputLabel className={classes.label} error={hasFieldError(formState)} required={required}>
                {label}
              </InputLabel>
              {multiline && (
                <Typography variant="caption" className={classes.clear} onClick={() => field.onChange('')}>
                  Clear
                </Typography>
              )}
            </Box>
          )}
          <TextField
            autoComplete={autoComplete}
            rows={rows}
            multiline={multiline}
            fullWidth={fullWidth}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
              ...(type === Type.Number && { inputProps: { min, max } }),
              ...(type === Type.Number && step && { inputProps: { step } }),
              startAdornment,
              endAdornment,
            }}
            classes={classes}
            {...field}
            size="small"
            // uncontrolled field should be set to '' instead of null or undefined
            onChange={(e) => field.onChange(e.target.value || '')}
            value={field.value || ''}
          />
          {hasFieldError(formState) && (
            <Box color="error.main" mt={0.5} fontSize="0.9rem" data-testid="validationError">
              {getFieldErrorMessage(formState)}
            </Box>
          )}
        </>
      )}
    />
  );
};

export default Input;
