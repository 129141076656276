import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, ListItemIcon } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import * as PropTypes from 'prop-types';
import WrapLink from '@uptime/shared/components/WrapLink';

import UserAvatar from '@uptime/shared/components/UserAvatar';
import { CooperationIcon } from '@uptime/shared/components/Icons';

import styles from './styles';

const UserMenu = (props) => {
  const { fullName, userEmail, userAvatarUrl, isLeft, logout, onStart } = props;
  const classes = styles();

  return (
    <Box className={classes.root}>
      {isLeft && (
        <Box className={classes.infoSection}>
          <Box style={{ display: 'flex' }} pl={5}>
            <Box>
              <h2>Finish Quick Start Guide</h2>
              <p className={classes.caption}>
                This wizard will walk you through the key steps to add your first task
              </p>
              <Button
                onClick={onStart}
                variant="contained"
                color="primary"
                component={WrapLink}
                to="/app/quick-start"
                data-testid="startGuideButton"
              >
                Let's Start
              </Button>
            </Box>
            <Box pt={4} pb={4}>
              <CooperationIcon />
            </Box>
          </Box>
        </Box>
      )}

      <Box position="relative">
        <Box className={classes.userSection}>
          <Avatar>
            <UserAvatar src={userAvatarUrl} name={fullName} />
          </Avatar>
          <Box style={{ marginLeft: 7 }}>
            <Box component="h4" m={0}>
              {fullName}
            </Box>
            <p className={classes.userEmail}>{userEmail}</p>
          </Box>
        </Box>
        <Box className={classes.navigationSection}>
          <List style={{ padding: 0 }}>
            <ListItem button component={WrapLink} to="/app/settings/account" data-testid="account">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem button onClick={logout} data-testid="logout">
              <ListItemIcon className={classes.logoutSection}>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

UserMenu.propTypes = {
  fullName: PropTypes.string,
  userEmail: PropTypes.string,
  userAvatarUrl: PropTypes.string,
  isLeft: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  onStart: PropTypes.func,
};

export default UserMenu;
