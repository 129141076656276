import { useEffect, useRef } from 'react';

const useOnUpdate = (effect: () => void, dependencies: any[]) => {
  const didMount = useRef(true);

  useEffect(() => {
    if (!didMount.current) return effect();

    didMount.current = false;
  }, dependencies);
};

export default useOnUpdate;
