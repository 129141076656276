import { gql } from '@apollo/client';

// #region FRAGMENT
const LOG_FRAGMENT = gql`
  fragment LogInfo on Log {
    id
    businessKey
    facilityId
    areaId
    uuid
  }
`;
// #endregion FRAGMENT

// #region MUTATION
export const startLog = gql`
  ${LOG_FRAGMENT}
  mutation startLog(
    $flowId: String!
    $businessKey: String!
    $facilityId: Int!
    $facilityTitle: String!
    $areaId: Int!
    $areaTitle: String!
    $deviceId: Int
    $deviceAssetId: String
  ) {
    startLog(
      flowId: $flowId
      businessKey: $businessKey
      facilityId: $facilityId
      facilityTitle: $facilityTitle
      areaId: $areaId
      areaTitle: $areaTitle
      deviceId: $deviceId
      deviceAssetId: $deviceAssetId
    ) {
      ...LogInfo
    }
  }
`;

export const startAndSaveLog = gql`
  ${LOG_FRAGMENT}
  mutation startAndSaveLog(
    $runTaskId: String!
    $flowId: String!
    $businessKey: String!
    $facilityId: Int!
    $facilityTitle: String!
    $areaId: Int!
    $areaTitle: String!
    $deviceId: Int
    $deviceAssetId: String
  ) {
    startAndSaveLog(
      runTaskId: $runTaskId
      flowId: $flowId
      businessKey: $businessKey
      facilityId: $facilityId
      facilityTitle: $facilityTitle
      areaId: $areaId
      areaTitle: $areaTitle
      deviceId: $deviceId
      deviceAssetId: $deviceAssetId
    ) {
      ...LogInfo
    }
  }
`;

export const saveInProgressLog = gql`
  mutation saveLog(
    $id: String!
    $processId: String!
    $flowId: String!
    $businessKey: String!
    $facilityId: Int!
    $areaId: Int
    $deviceId: Int
    $logData: JSONObject!
    $readData: JSONObject!
  ) {
    saveLog(
      id: $id
      processId: $processId
      flowId: $flowId
      businessKey: $businessKey
      facilityId: $facilityId
      areaId: $areaId
      deviceId: $deviceId
      logData: $logData
      readData: $readData
    ) {
      id
    }
  }
`;

export const sendLogComplianceReportEmail = gql`
  mutation sendLogComplianceReportEmail(
    $to: [String!]!
    $cc: [String!]
    $accountId: Int!
    $flowIds: [String!]
    $facilityIds: [Int!]
    $areaIds: [Int!]
    $deviceIds: [Int!]
    $performedByIds: [Int!]
    $fromDate: Int
    $toDate: Int
    $userName: String!
  ) {
    sendLogComplianceReportEmail(
      to: $to
      cc: $cc
      accountId: $accountId
      flowIds: $flowIds
      facilityIds: $facilityIds
      areaIds: $areaIds
      deviceIds: $deviceIds
      performedByIds: $performedByIds
      fromDate: $fromDate
      toDate: $toDate
      userName: $userName
    )
  }
`;

export const restartLog = gql`
  mutation restartLog($logId: String!) {
    restartLog(logId: $logId) {
      id
    }
  }
`;

export const deleteLog = gql`
  mutation deleteLog($logId: String!) {
    deleteLog(logId: $logId)
  }
`;
// #endregion MUTATION

// #region QUERY
export const logById = gql`
  query logById($logId: String!) {
    logById(id: $logId) {
      id
      performedBy
      status
      completedAt
      businessKey
      processId
      accountId
      flowId
      facilityId
      areaId
      deviceId
      version
      readData
      logData
    }
  }
`;

export const logList = gql`
  query logList(
    $filter: LogFilterInput!
    $sortBy: [LogListSortInput]
    $pagination: PaginationInput = { page: 1, itemsAmount: 10 }
  ) {
    logList(filter: $filter, sortBy: $sortBy, pagination: $pagination) {
      hits {
        id
        accountId
        flowId
        facilityId
        facilityTitle
        areaId
        areaTitle
        deviceId
        version
        readData
        logData
        flow {
          title
        }
        createdAt
        createdBy {
          name
        }
        updatedAt
        runTaskId
      }
      currentPage
      pageCount
      itemsCount
    }
  }
`;

export const getLogsCount = gql`
  query logListCount($filter: LogFilterInput!) {
    logList(filter: $filter) {
      itemsCount
    }
  }
`;

export const logHeaders = gql`
  query logHeaders($flowId: String!) {
    logHeaders(flowId: $flowId) {
      flowId
      columnData {
        key
        title
        page
      }
    }
  }
`;

export const totalLogsCompleted = gql`
  query totalLogsCompleted($filter: LogFilterInput!) {
    totalLogsCompletedData(filter: $filter)
  }
`;

export const getLogFileUrl = gql`
  query getLogFileUrl($userId: Int!, $fileId: String!) {
    getLogFileUrl(userId: $userId, fileId: $fileId) {
      url
      mimeType
    }
  }
`;

export const getLastOpenedBox = gql`
  query getLastOpenedBox($accountId: Int!, $flowId: String!, $facilityId: Int!, $areaId: Int!) {
    lastGlucoseBox(accountId: $accountId, flowId: $flowId, facilityId: $facilityId, areaId: $areaId) {
      id
      accountId
      flowId
      facilityId
      areaId
      deviceId
      version
      readData
      logData
      createdAt
    }
  }
`;

export const getLastMonitorQCSerialNumbers = gql`
  query getLastMonitorQCSerialNumbers($accountId: Int!, $flowId: String!, $facilityId: Int!, $areaId: Int!) {
    lastMonitorQCSerialNumbers(
      accountId: $accountId
      flowId: $flowId
      facilityId: $facilityId
      areaId: $areaId
    ) {
      id
      accountId
      flowId
      facilityId
      areaId
      deviceId
      version
      readData
      logData
      createdAt
    }
  }
`;

export const getLogsDevicePhantomNumberList = gql`
  query getLogsDevicePhantomNumberList($flowId: String!, $deviceId: Int!, $accountId: Int!) {
    list: logsDevicePhantomNumberList(flowId: $flowId, deviceId: $deviceId, accountId: $accountId)
  }
`;

export const hasGlucoseBox = gql`
  query hasGlucoseBox($accountId: Int!, $flowId: String!, $facilityId: Int!, $areaId: Int!) {
    hasGlucoseBox(accountId: $accountId, flowId: $flowId, facilityId: $facilityId, areaId: $areaId)
  }
`;

export const hasMonitorQCSerialNumbers = gql`
  query hasMonitorQCSerialNumbers($accountId: Int!, $flowId: String!, $facilityId: Int!, $areaId: Int!) {
    hasMonitorQCSerialNumbers(
      accountId: $accountId
      flowId: $flowId
      facilityId: $facilityId
      areaId: $areaId
    )
  }
`;

export const getLogsPhantomDevicesIds = gql`
  query logsPhantomDevicesIds($flowId: String!, $accountId: Int!) {
    logsPhantomDevicesIds(flowId: $flowId, accountId: $accountId)
  }
`;

export const exportResolutLog = gql`
  query exportResolutLog($filter: LogFilterInput!, $pagination: PaginationInput) {
    exportResolutLog(filter: $filter, pagination: $pagination)
  }
`;

export const exportHomogeneityToExcel = gql`
  query exportHomogeneityToExcel($filter: LogFilterInput!, $pagination: PaginationInput) {
    exportHomogeneityToExcel(filter: $filter, pagination: $pagination)
  }
`;
// #endregion QUERY
