import { useContext, useEffect } from 'react';
import { getMappedJwtPayload, getMappedCognitoPayload } from '@uptime/shared/utils/general';
import { useCognito } from 'libs/providers/CognitoProvider';
import useJwt from 'shared/hooks/useJwt';
import AuthContext from 'context/AuthContext';
import { PermissionsContext } from 'providers/PermissionsProvider';

const AuthSync = ({ children }) => {
  const { updateAuth, userId } = useContext(AuthContext);
  const { addPermissionsFromToken } = useContext(PermissionsContext);
  const { auth, authorized } = useJwt();
  const { isAuthorized } = useCognito();

  useEffect(() => {
    if (!authorized || !auth) return;

    const payload = getMappedJwtPayload(auth);
    updateAuth(payload);
    addPermissionsFromToken(payload);
    // eslint-disable-next-line
  }, [userId, auth]);

  useEffect(() => {
    if (isAuthorized) {
      const payload = getMappedCognitoPayload();
      updateAuth(payload);
    }
    // eslint-disable-next-line
  }, [isAuthorized]);
  return children;
};

export default AuthSync;
