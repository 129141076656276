export const TASK_TYPES = {
  d: 'daily',
  w: 'weekly',
  m: 'monthly',
  n: 'never',
};
export const TASK_TYPES_REVERSE = {
  daily: 'd',
  weekly: 'w',
  monthly: 'm',
  never: 'n',
};
export const DUE_DATE_TYPES = {
  yesterdayAndToday: 'yesterdayAndToday',
  thisWeek: 'thisWeek',
  thisAndNextMonth: 'thisAndNextMonth',
  pastThirtyDays: 'pastThirtyDays',
  pastNinetyDays: 'pastNinetyDays',
};

export const TASK_FOR_AREA = 'area';
export const TASK_FOR_DEVICE = 'device';
export const TASK_BY_USER = 'user';
export const TASK_BY_GROUP = 'group';

export const INITIAL_PAGINATION_CONFIG = {
  page: 1,
  itemsAmount: 50,
};

export const HOME_PAGE_PAGINATION_CONFIG = {
  page: 1,
  itemsAmount: 5,
};

export const PAST_TASKS = 'Past Tasks';
export const IN_PROGRESS_TASKS = 'Tasks To Be Completed';
export const INIT_FILTERS = {
  type: IN_PROGRESS_TASKS,
  assignedUserId: null,
  facilityIds: [],
  areaIds: [],
  due: DUE_DATE_TYPES.yesterdayAndToday,
};

export const DUE_DATE_OPTIONS = [
  {
    label: 'Tasks for yesterday and today',
    value: DUE_DATE_TYPES.yesterdayAndToday,
  },
  {
    label: 'Tasks for this week',
    value: DUE_DATE_TYPES.thisWeek,
  },
  {
    label: 'Tasks for this month and next month',
    value: DUE_DATE_TYPES.thisAndNextMonth,
  },
  {
    label: 'Tasks for past 30 days',
    value: DUE_DATE_TYPES.pastThirtyDays,
  },
  {
    label: 'Tasks for past 90 days',
    value: DUE_DATE_TYPES.pastNinetyDays,
  },
];

export const RUN_TASK_TYPE_OPTIONS = [
  {
    label: IN_PROGRESS_TASKS,
    value: IN_PROGRESS_TASKS,
  },
  {
    label: PAST_TASKS,
    value: PAST_TASKS,
  },
];

export const RUN_TASK_TYPES = {
  incomplete: 'incomplete',
  complete: 'complete',
  inProgress: 'in_progress',
};

export const BUILDER_TABS = {
  builder: 0,
  calendar: 1,
};

export const TASK_HISTORY_REPORT_TYPE = {
  FACILITY: 'FACILITY',
  DEVICE: 'DEVICE',
};

export const DIRTY_VALUES_WARNING = `This change will affect completed tasks.
 Please COPY to create a new task OR proceed if OK.`;

export const TASKS_BULK_UPDATE_FIELDS = {
  TASK_DESCRIPTION: 'TASK_DESCRIPTION',
  TASK_NAME: 'TASK_NAME',
  TASK_SUPPORTING_LINK: 'TASK_SUPPORTING_LINK',
  TASK_CATEGORY: 'TASK_CATEGORY',
  TASK_AREA: 'TASK_AREA',
  TASK_DEVICE: 'TASK_DEVICE',
  TASK_GROUP: 'TASK_GROUP',
  TASK_USER: 'TASK_USER',
  TASK_RECURRENCE: 'TASK_RECURRENCE',
  TASK_SUB_TASK_NOTE_REQUIRED: 'TASK_SUB_TASK_NOTE_REQUIRED',
};

export const TASK_FIELD_TEXT = {
  [TASKS_BULK_UPDATE_FIELDS.TASK_SUPPORTING_LINK]: 'Supporting Link',
  [TASKS_BULK_UPDATE_FIELDS.TASK_NAME]: 'Name',
  [TASKS_BULK_UPDATE_FIELDS.TASK_DESCRIPTION]: 'Description',
  [TASKS_BULK_UPDATE_FIELDS.TASK_CATEGORY]: 'Category',
  [TASKS_BULK_UPDATE_FIELDS.TASK_AREA]: 'Facility / Area',
  [TASKS_BULK_UPDATE_FIELDS.TASK_DEVICE]: 'Facility / Asset ID',
  [TASKS_BULK_UPDATE_FIELDS.TASK_GROUP]: 'Group',
  [TASKS_BULK_UPDATE_FIELDS.TASK_USER]: 'User',
  [TASKS_BULK_UPDATE_FIELDS.TASK_RECURRENCE]: 'Recurrence',
  [TASKS_BULK_UPDATE_FIELDS.TASK_SUB_TASK_NOTE_REQUIRED]: 'Sub-Task Note Required',
};

export const TASK_FIELD_OPTIONS = Object.keys(TASKS_BULK_UPDATE_FIELDS)
  .map((k) => ({
    label: TASK_FIELD_TEXT[k],
    value: TASKS_BULK_UPDATE_FIELDS[k],
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
