import { flowClient, userClient } from 'apolloClientConfiguration';
import { signInAsUnifiedImagingUser } from '@uptime/shared/graphql/users';
import { sendIntegrationMessageQuery } from '@uptime/shared/graphql/workOrders';
import { parseJwt, responseLens } from '@uptime/shared/utils/general';
import { JWT_TOKEN_STORAGE_KEY } from '@uptime/shared/constants';

const login = (token) =>
  userClient.mutate({
    mutation: signInAsUnifiedImagingUser,
    variables: { token },
  });

export const sendIntegrationMessage = ({ processId, message, provider }) =>
  flowClient.mutate({
    mutation: sendIntegrationMessageQuery,
    variables: {
      processId,
      message,
      provider,
    },
  });

export const loginAsUnifiedImagingToken = async ({ identifier }) => {
  const {
    data: { token },
  } = await login(identifier);

  const tokenPayload = parseJwt(token);

  const storage = JSON.parse(localStorage.getItem(JWT_TOKEN_STORAGE_KEY));

  const auth = {
    ...tokenPayload,
    token,
    UI: {
      identifier,
      times: responseLens(storage, 'UI.times', 0) + 1,
    },
  };

  localStorage.setItem(JWT_TOKEN_STORAGE_KEY, JSON.stringify(auth));

  return auth;
};
