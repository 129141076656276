import React from 'react';
import { Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type Props = {
  visible: boolean;
  onShow: () => void;
};

const ShowPassword = ({ visible, onShow }: Props) => (
  <Box mr={-2}>
    <IconButton onClick={onShow} size="small">
      {visible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
    </IconButton>
  </Box>
);

export default ShowPassword;
