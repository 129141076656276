import styled from 'styled-components';
import { MaterialDesignContent } from 'notistack';

import muiTheme from './muiTheme';

const ThemedSnackbar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: muiTheme.palette.success.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: muiTheme.palette.error.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: muiTheme.palette.warning.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: muiTheme.palette.primary.main,
  },
}));

export default ThemedSnackbar;
