import { Badge } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: theme.components.MuiDrawer.styleOverrides.root.width,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.components.MuiDrawer.styleOverrides.root.width}px)`,
    },
    color: theme.typography.color,
    boxShadow: '20px 2px 44px rgba(0, 0, 0, 0.04) !important', // Todo: move to theme
    backgroundColor: theme.palette.common.white,
    '@media print': {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  downLoadBtn: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark1,
    },
  },
  helpBtn: {
    color: theme.palette.grey[700],
    borderRadius: '10%',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light2,
    },
  },
  toolbar: {
    padding: '0 32px',
  },
  title: {
    color: theme.palette.grey[900],
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
    },
  },
  accountPreview: {
    padding: '0 !important',
    borderRadius: '10%',
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  accountPreviewItem: {
    borderRadius: 5,
    borderLeft: 0,
    padding: 0,
    margin: 0,
    '&:hover': {
      boxShadow: 'none',
    },
    '& .MuiListItemText-multiline': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
    },
  },
  accountPreviewAvatar: {
    minWidth: 'auto',
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  bigAvatar: {
    margin: theme.spacing(1),
    width: 128,
    height: 128,
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.16)', // Todo: move to theme
  },
  buttonBox: {
    width: '100%',
  },
  paperOverride: {
    borderRadius: 12,
  },
}));

export const StyledBadge = withStyles((theme) => ({
  badge: {
    position: 'absolute',
    top: 30,
    right: 5,
    height: 8,
    minWidth: 8,
    backgroundColor: theme.palette.success.main,
  },
}))(Badge);
