import React, { useContext, createContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useOnUpdate } from 'libs/hooks';
import * as Sentry from 'libs/services/sentry';
import * as LogRocket from 'libs/services/log-rocket';
import * as UserPilot from 'libs/services/userpilot';

import AuthContext from 'context/AuthContext';
import { SourceContext } from 'providers/SourceProvider';

type Props = {
  children: React.ReactNode;
};

const ThirdPartyIntegrationsContext = createContext({});

export const ThirdPartyIntegrationsProvider = ({ children }: Props) => {
  const ref = useRef(false);
  const state = useContext(AuthContext);
  const source = useContext(SourceContext);
  const location = useLocation();

  useOnUpdate(
    () => {
      if (!state.userId) return;

      const lastLogin = (source.profile as any)?.lastLogin;

      Sentry.initialize(state);
      LogRocket.initialize(state);
      UserPilot.initialize({
        ...state,
        ...source,
        ...(lastLogin && { lastLogin: new Date(lastLogin * 1000) }),
      });

      ref.current = true;
    },
    // @ts-ignore
    [state.userId, source?.profile?.userId]
  );

  useOnUpdate(() => {
    if (!ref.current) return;

    UserPilot.reload();
  }, [location]);

  return (
    <ThirdPartyIntegrationsContext.Provider value={{}}>{children}</ThirdPartyIntegrationsContext.Provider>
  );
};
