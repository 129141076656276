import React from 'react';
import * as PropTypes from 'prop-types';

import { StyledLoading } from './styles';

const LoadingPlaceholder = ({ title = 'Loading' }) => {
  return <StyledLoading>{title}</StyledLoading>;
};

LoadingPlaceholder.propTypes = {
  title: PropTypes.string,
};

export default LoadingPlaceholder;
