import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BusinessIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 43" {...props}>
      <g>
        <g>
          <path d="M46.4 27.28a.798.798 0 0 1-.688.79l-.222.03-18.29 2.511v-1.148a2.395 2.395 0 0 0-2.4-2.389h-1.6c-1.325 0-2.4 1.07-2.4 2.389v1.148l-18.511-2.54a.798.798 0 0 1-.689-.79V9.556c0-.44.358-.797.8-.797h43.2c.442 0 .8.357.8.797zm-20.8 6.96c0 .44-.358.797-.8.797h-1.6a.798.798 0 0 1-.8-.796v-4.778c0-.44.358-.796.8-.796h1.6c.442 0 .8.356.8.796zm19.2 6.38c0 .44-.358.797-.8.797H4a.799.799 0 0 1-.8-.797V29.803l17.6 2.418v2.024a2.396 2.396 0 0 0 2.4 2.39h1.6c1.325 0 2.4-1.07 2.4-2.39V32.22l17.6-2.418zM30.4 7.167H17.6v-2.39h12.8zm-16-3.181a2.397 2.397 0 0 1 2.4-2.393h14.4c1.325 0 2.4 1.071 2.4 2.393v3.19H32v-3.19a.799.799 0 0 0-.8-.798H16.8c-.442 0-.8.357-.8.798v3.19h-1.6zm31.2 3.18H35.2V3.982C35.197 1.784 33.408.003 31.2 0H16.8c-2.208.003-3.997 1.784-4 3.981v3.186H2.4c-1.325 0-2.4 1.07-2.4 2.389V27.28a2.4 2.4 0 0 0 1.6 2.248v11.082A2.395 2.395 0 0 0 4 43h40c1.325 0 2.4-1.07 2.4-2.389V29.53a2.4 2.4 0 0 0 1.6-2.25V9.557a2.395 2.395 0 0 0-2.4-2.39z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default BusinessIcon;
