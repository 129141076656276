import { useParams } from 'react-router-dom';
import UnifiedImagingLoginHook from './hooks/unified-imaging-login.hook';
import ImpersonateLoginHook from './hooks/impersonate-login.hook';

const LOGIN_VARIANTS = {
  impersonate: ImpersonateLoginHook,
  'unified-imaging': UnifiedImagingLoginHook,
};

const useJwtLogin = () => {
  const { type } = useParams();

  const hook = LOGIN_VARIANTS[type];

  if (!hook) {
    return {
      error: 'Not supported authorization mode',
    };
  }

  return hook();
};

export default useJwtLogin;
