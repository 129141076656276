import Box from '@mui/material/Box';
import useStyles from './styles';

type BadgeProps = {
  badgeContent: number | string;
};

const Badge = ({ badgeContent }: BadgeProps) => {
  const classes = useStyles();

  if (Number(badgeContent) === 0) return null;

  return <Box className={classes.container}>{badgeContent}</Box>;
};

export default Badge;
